import {
  WriteBatch,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import type {
  GroupDocumentWithSubscription,
  GroupDocument,
  FeatureFlag,
  FeatureFlags,
} from '@og-shared/types';

export const firestoreListenToGroup = (
  groupId: string,
  callback: (group: GroupDocumentWithSubscription | null) => void
) => {
  const path = `groups/${groupId}`;
  const docRef = doc(getFirestore(), path);
  const groupListener = onSnapshot(
    docRef,
    snap => {
      const group = snap.data() as GroupDocumentWithSubscription;
      callback(group);
    },
    error => {
      console.error('error loading group', { error, groupId });
      callback(null);
    }
  );

  return groupListener;
};

export async function firestoreUpdateGroup(
  group_id: string,
  update: Partial<GroupDocument>,
  batch: WriteBatch | undefined
) {
  const path = `groups/${group_id}`;
  const docRef = doc(getFirestore(), path);
  if (batch) {
    return batch.set(docRef, update, { merge: true });
  } else {
    return setDoc(docRef, update, { merge: true });
  }
}

export async function firestoreSetGroupUncategorizedCountToZero(
  group_id: string
) {
  const path = `groups/${group_id}`;
  const docRef = doc(getFirestore(), path);
  const update: Partial<GroupDocument> = {
    uncategorized_transactions_count: 0,
    uncategorized_transactions_amount: 0,
  };
  return setDoc(docRef, update, { merge: true });
}

export async function firestoreUpdateGroupFeatures(
  group_id: string,
  feature: keyof FeatureFlags,
  value: FeatureFlag
) {
  const path = `groups/${group_id}`;
  const docRef = doc(getFirestore(), path);
  const update: Partial<GroupDocument> = {
    features: {
      [feature]: value,
    },
  };
  return setDoc(docRef, update, { merge: true });
}

export const firestoreGetGroups = async (group_ids: string[]) => {
  const groups = await Promise.all(
    group_ids.map(groupId => firestoreGetGroup(groupId))
  );
  return groups;
};

export const firestoreGetGroup = async (group_id: string) => {
  const docRef = doc(getFirestore(), `groups/${group_id}`);
  const snap = await getDoc(docRef);
  return snap.data() as GroupDocumentWithSubscription;
};
